<template>
  <section id="philosophy" class="bg-philosophy bg-cover bg-right-top h-[100vh] md:h-[100vh] w-full flex justify-center items-center">
    <div class="absolute opacity-50 bg-black w-full h-[100vh] md:h-[100vh]"></div>
    <div class="absolute flex flex-col justify-center items-center text-center  text-white">
        <p class="text-[18.56px] md:text-[42px]">
            Coffee makes our lives brighter. 
        </p>
        <p class="text-[18.56px] md:text-[42px]">
            We're here to make your coffee
            experience better.
        </p>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
</style>