<template>
  <nav class="">
    <div
      class="bg-[#38220f] flex flex-row justify-end md:justify-start px-5 py-2"
    >
      <div class="flex justify-center flex-row gap-2">
        <div
          class="text-[#f3f0ed] hidden md:flex md:flex-row gap-1"
          v-for="(item, i) in scrollNav"
          :key="i"
        >
          <button
            class="py-2 px-4 md:mx-0 font-semibold snap-y rounded-md bg-[#3f2d1e] active:bg-[#351e0b] focus:bg-[#351e0b] hover:bg-[#351e0b] cursor-pointer "
            @click="item.scroll"
          >
            {{ item.label }}
          </button>

          <!-- <button class="custom-button-1" @click="scrollDesktop('home')">
            Home
          </button>
          <button class="custom-button-1" @click="scrollDesktop('about')">
            About
          </button>
          <button class="custom-button-1" @click="scrollDesktop('whatwedo')">
            What We Do
          </button>
          <button class="custom-button-1" @click="scrollDesktop('philosophy')">
            Philosophy
          </button>
          <button class="custom-button-1" @click="scrollDesktop('faqs')">
            FAQs
          </button>
          <button class="custom-button-1" @click="scrollDesktop('contact')">
            Contact
          </button> -->
        </div>
        <div class="md:hidden">
          <button
            v-if="!isOpen"
            class="flex items-center cursor-pointer"
            @click="showNav"
          >
            <box-icon
              class="fill-white h-8 aspect-square"
              size="cssSize"
              name="menu-alt-right"
            ></box-icon>
          </button>
          <button
            class="flex items-center cursor-pointer"
            v-if="isOpen"
            @click="showNav"
          >
            <box-icon
              class="fill-white h-8 aspect-square"
              size="cssSize"
              name="x"
            ></box-icon>
          </button>
        </div>
      </div>
      <!-- mobilenav -->
      <transition
        enter-active-class="animate__animated animate__fadeInLeft"
        leave-active-class="animate__animated animate__fadeOutLeft"
      >
        <div
          class="
            md:hidden
            bg-[#38220f]
            text-[#f3f0ed]
            absolute
            left-0
            top-[48px]
            flex flex-col
            items-start
            p-5
            gap-5
            w-1/2
            py-10
            h-full
            z-10
            text-lg
            font-semibold
            transition
            duration-300
          "
          v-show="isOpen"
        >
          <button class="custom-button-1" @click="scroll('home')">Home</button>
          <button class="custom-button-1" @click="scroll('about')">
            About
          </button>
          <button class="custom-button-1" @click="scroll('whatwedo')">
            What We Do
          </button>
          <button class="custom-button-1" @click="scroll('philosophy')">
            Philosophy
          </button>
          <button class="custom-button-1" @click="scroll('faqs')">FAQs</button>
          <button class="custom-button-1" @click="scroll('contact')">
            Contact
          </button>
        </div>
      </transition>
      <div
        v-show="isOpen"
        @click="showNav"
        class="
          md:hidden
          absolute
          left-0
          top-[48px]
          bg-black
          opacity-50
          h-full
          w-full
          z-[9]
        "
      ></div>
    </div>
  </nav>
</template>

<script>
export default {
  components: {},
  data: () => ({
    isOpen: false,
  }),
  methods: {
    showNav() {
      let sitebody = document.body;
      this.isOpen = !this.isOpen;
      // disabled scroll when nav is open
      this.isOpen
        ? sitebody.classList.add("overflow-hidden")
        : sitebody.classList.remove("overflow-hidden");
      console.log(`open`);
    },
    scroll(refName) {
      let sitebody = document.body;
      this.isOpen = !this.isOpen;
      this.isOpen
        ? sitebody.classList.add("overflow-hidden")
        : sitebody.classList.remove("overflow-hidden");
      console.log(`open`);

      const element = document.getElementById(refName);
      element.scrollIntoView({ behavior: "smooth" });
    },
    scrollDesktop(refName) {
      // let sitebody = document.body;
      // sitebody.classList.remove('overflow-hidden')
      const element = document.getElementById(refName);
      element.scrollIntoView({ behavior: "smooth" });
    },
  },
  computed: {
    scrollNav() {
      return [
        {
          label: "Home",
          scroll: () => {
            const element = document.getElementById("home");
            element.scrollIntoView({ behavior: "smooth" });
          },
        },
        {
          label: "About",
          scroll: () => {
            const element = document.getElementById("about");
            element.scrollIntoView({ behavior: "smooth" });
          },
        },
        {
          label: "What We Do",
          scroll: () => {
            const element = document.getElementById("whatwedo");
            element.scrollIntoView({ behavior: "smooth" });
          },
        },
        {
          label: "Philosophy",
          scroll: () => {
            const element = document.getElementById("philosophy");
            element.scrollIntoView({ behavior: "smooth" });
          },
        },
        {
          label: "FAQs",
          scroll: () => {
            const element = document.getElementById("faqs");
            element.scrollIntoView({ behavior: "smooth" });
          },
        },
        {
          label: "Contact",
          scroll: () => {
            const element = document.getElementById("contact");
            element.scrollIntoView({ behavior: "smooth" });
          },
        },
      ];
    },
  },
};
</script>


<style scoped>
</style>

