<template>
  <div>
    <Navbar />
    <HomeView />
    <AboutView />
    <WhatWeDo />
    <Philosophy />
    <Faq/>
    <Contact />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import HomeView from "@/views/HomeView";
import AboutView from "@/views/AboutView";
import WhatWeDo from "@/views/WhatWeDo";
import Philosophy from "@/views/Philosophy";
import Faq from "@/views/Faq";
import Contact from "@/views/Contact";

export default {
  components: {
    Navbar,
    HomeView,
    AboutView,
    WhatWeDo,
    Contact,
    Philosophy,Faq
  },
};
</script>

<style>
</style>