<template>
  <section id="whatwedo" class="bg-[#fff4f0] p-5 snap-center">
    <h4></h4>
    <div
      class="
        flex flex-col
        gap-5
        xl:gap-10
        justify-center
        items-center
        md:flex-row
        p-5
        md:p-0 md:px-12 lg:px-12
      "
    >
      <div v-for="(item, i) in images" :key="i">
        <div class="rounded-md">
          <img
            class="rounded-md "
            
            :src="item.name"
            alt=""
          />
          <span class="font-semibold text-lg md:text-2xl"> {{ item.subtitle }}</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data: () => ({
    images: [
      {
        name: [require("@/assets/images/bread-croissant.jpg")],
        subtitle: "Croissant",
      },
      {
        name: [require("@/assets/images/cake.jpg")],
        subtitle: "Cake",
      },
      {
        name: [require("@/assets/images/donuts&desserts.jpg")],
        subtitle: "Donuts",
      },
    ],
  }),
};
</script>

<style>
</style>