<template>
  <section class="bg-hero bg-cover h-[calc(100vh-2.5rem)] flex" id="home">
    <div class="opacity-50 bg-black h-full w-full "></div>
    <div class="absolute flex flex-col md:grid md:grid-cols-2 align-center w-full h-full">
      <div class="w-full md:h-full ">
        <!-- for positioning -->
      </div>
      <div class="flex flex-col items-start justify-center px-5  text-white  gap-5 md:gap-3 h-full  ">
        <h4 class="text-5xl xl:text-[88px] leading-[.97] max-w-[785px]">Vigilatte Coffee House</h4>
        <p class="text-lg md:text-[28px] pb-5 md:pb-0">Have a sip, survive the work week</p>
        <button class="rounded-full bg-[#c4923e] text-lg py-2.5 px-6">See Our Menu</button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data:()=>({
    heroBg: [require("@/assets/images/hero-bg.jpg")]
  })
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap');
h4{
  font-family: 'Source Sans Pro', sans-serif;
 
}
</style>