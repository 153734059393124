<template>
  <section class="bg-[#fff4f0]" id="faqs">
    <div class="md:grid md:grid-cols-2 align-center h-auto">
      <div class="p-5 rounded-md md:p-[5rem] md:pr-0">
        <img class="rounded-md" :src="faq" alt="" />
      </div>
      <div class="flex flex-col justify-center items-center p-4">
        <div class="flex flex-col md:w-[auto] lg:w-[494px] md:max-w-[494px]">
            <h3 class="text-[37.47px] md:text-[48px] leading-[.97]">Frequently Ask Questions</h3>
          <p class="text-[18.73px] pt-4">
            Do you deliver? Absolutely! You can find us on all mobile delivery
            platforms.
          </p>
          <p class="text-[18.73px] pt-4">
            Are you open on weekends? Yes! On weekends, we are open from 9 AM to
            9 PM.
          </p>
          <p class="text-[18.73px] pt-4">
            Do you deliver? Absolutely! You can find us on all mobile delivery
            platforms
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data: () => ({
    faq: [require("@/assets/images/Faq.jpeg")],
  }),
};
</script>

<style>
</style>