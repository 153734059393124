<template>
  <section class="mt-5" id="about">
    <div class=" md:grid md:grid-cols-2 align-center h-auto ">
      <div class="flex flex-col justify-center items-center p-4">
        <div class="flex flex-col md:w-[auto] lg:w-[494px] md:max-w-[494px] ">
          <span class="text-[37.47px] md:text-[48px] leading-[.97]">
            Your coffee 
          </span>
          <span class="text-[37.47px] md:text-[48px] leading-[.97]">
            experts are here to save the day
          </span>
          <p class="text-[18.73px] pt-4">
            Like the vigilante heroes in our beloved comic books, we saw the
            injustice in our local cafe scene. The sky-high prices. The limited
            coffee options. The lack of comfy couches. 
          </p>
          <p class="text-[18.73px] pt-4">
            So we took matters into
            our own hands and Vigilatte Coffee House was born.
          </p>
        </div>
      </div>
      <div class="p-5 rounded-md md:p-[5rem] md:pr-0">
        <img class="rounded-md" :src="aboutBg" alt="" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data: () => ({
    aboutBg: [require("@/assets/images/milk-to-coffee.jpg")],
  }),
};
</script>

<style>
</style>