<template>
  <section id="contact"
    class="
      bg-contact bg-cover bg-right-top
      h-[100vh] md:h-[100vh]
      w-full
      flex
      items-center
      md:justify-center md:text-center
      mt-5
    "
  >
    <div class="absolute opacity-50 bg-black w-full h-[100vh] md:h-[100vh]"></div>
    <div
      class="
        absolute
        flex flex-col
        justify-left
        md:justify-center
        items-start
        text-left text-white
        p-5
      "
    >
      <p class="text-4xl font-semibold md:text-[42px] mb-1">Contact Us</p>
      <span class="text-lg text-[#fff4f0]"
        >123 Anywhere St., Any City, ST 12345</span
      >
      <span class="text-lg text-[#fff4f0]">23-456-7890</span>
      <span class="text-lg text-[#fff4f0]">hello@reallygreatsite.com</span>
      <span class="text-lg text-[#fff4f0]">@reallygreatsite</span>
      <span class="text-lg mt-4 text-[#fff4f0]"
        >Visit our coffee house today!</span
      >
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
</style>